import React from 'react';
import { bool } from 'prop-types';

import PriceFilterPlain from './PriceFilterPlain';
import PriceFilterPopup from './PriceFilterPopup';
import PriceFilterPopupInMap from './PriceFilterPopupInMap';

const PriceFilter = props => {
  const { showAsPopup, marketplaceCurrency, ...rest } = props;
  return showAsPopup ? (
    // <PriceFilterPopup marketplaceCurrency={marketplaceCurrency} {...rest} />
    <PriceFilterPopupInMap marketplaceCurrency={marketplaceCurrency} {...rest} />
  ) : (
    <PriceFilterPlain marketplaceCurrency={marketplaceCurrency} {...rest} />
  );
};

PriceFilter.defaultProps = {
  showAsPopup: false,
};

PriceFilter.propTypes = {
  showAsPopup: bool,
};

export default PriceFilter;
